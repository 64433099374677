const getSponsorLogoUrl = (costs) => {
  const DEFAULT_LOGO_URL = null;
  const isSponsorsEmpty = costs?.length === 0;
  const isNotArrayUrl = !Array.isArray(costs);
  const isSponsorsInvalid = isSponsorsEmpty || isNotArrayUrl;
  if (isSponsorsInvalid) return DEFAULT_LOGO_URL;

  return costs[0]?.sponsorLogo?.localFile?.publicURL;
};

export default getSponsorLogoUrl;
