const getSponsorLabel = (costs) => {
  const DEFAULT_TYPE = null;
  const isSponsorsEmpty = costs?.length === 0;
  const isNotArrayType = !Array.isArray(costs);
  const isSponsorsInvalid = isSponsorsEmpty || isNotArrayType;
  if (isSponsorsInvalid) return DEFAULT_TYPE;

  return costs[0]?.label;
};

export default getSponsorLabel;
