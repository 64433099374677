import React, { useState, useEffect } from 'react';
import Select from 'antd/lib/select';
import useCoursesResultsFilter from '@modules/CareerDevelopment/Courses/Results/Viewer/AdditionalFilters/helpers/hooks/useCoursesResultsFilter';

const options = {
  format: [
    { value: 'All', label: 'All' },
    { value: 'onsite', label: 'Onsite' },
    { value: 'online', label: 'E - learning' },
    { value: 'distance', label: 'Distance' },
  ],
  cost: [
    { value: 'all', label: 'All' },
    { value: 'free', label: 'Free' },
    { value: 'paid', label: 'Paid' },
    { value: 'sponsored', label: 'Sponsored' },
  ],
  duration: [
    { value: 'All', label: 'Any Duration' },
    { value: '<1d', label: 'Less than 1 day' },
    { value: '1-4w', label: '1 - 4 weeks' },
    { value: '1-3m', label: '1 - 3 months' },
    { value: '4-6m', label: '4 - 6 months' },
    { value: '7-12m', label: '7 -12 months' },
    { value: '>12m', label: '12+  months' },
  ],
  level: [
    { value: 'All', label: 'All' },
    { value: 'beg', label: 'Beginner' },
    { value: 'int', label: 'Intermediate' },
    { value: 'adv', label: 'Advanced' },
    { value: 'com', label: 'Comprehensive' },
  ],
};

const DEFAULT_VALUE = 'All';

const SelectFilter = ({
  label = '',
  filterKey = '',
  size = 'small',
  queryParamsOnChange = true,
  value = '',
  onChange,
}) => {
  const [filters, setFilters] = useCoursesResultsFilter();
  const [hasDefaultValue, setHasDefaultValue] = useState();

  useEffect(() => {
    if (queryParamsOnChange) {
      setHasDefaultValue(
        filters[filterKey] === DEFAULT_VALUE || !filters[filterKey]
      );
    } else {
      setHasDefaultValue(value === DEFAULT_VALUE);
    }
  }, [value, filters[filterKey]]);

  const handleSelectChange = (selectValue) => {
    if (queryParamsOnChange) {
      setFilters({ [filterKey]: selectValue });
    }

    if (onChange) {
      onChange(selectValue);
    }
  };

  const getSelectProps = () => {
    let selectProps = {
      id: filterKey,
      options: options[filterKey],
      defaultValue: DEFAULT_VALUE,
      dropdownClassName: 'additional-select-filters',
      className: hasDefaultValue ? '' : 'active-filter',
      onChange: handleSelectChange,
    };

    if (!queryParamsOnChange) {
      selectProps = {
        ...selectProps,
        value: value,
      };
    }

    if (queryParamsOnChange) {
      selectProps = {
        ...selectProps,
        value: filters[filterKey],
      };
    }

    return selectProps;
  };

  return (
    <div className={`select-filter ${size}`}>
      <label className="filter-label" for={filterKey}>
        {label}
      </label>
      <Select {...getSelectProps()} />
    </div>
  );
};

export default SelectFilter;
