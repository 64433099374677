import React from 'react';
import SelectFilter from '@modules/CareerDevelopment/Courses/Results/Viewer/AdditionalFilters/SelectFilter';
import DatePickerFilter from '@modules/CareerDevelopment/Courses/Results/Viewer/AdditionalFilters/DatePickerFilter';
import ClearFilter from '@modules/CareerDevelopment/Courses/Results/Viewer/AdditionalFilters/ClearFilter';
import {
  FORMAT,
  COST,
  START,
  DURATION,
  LEVEL,
} from '@modules/CareerDevelopment/Courses/Results/Viewer/AdditionalFilters/helpers/constants';

const NavMenu = () => {
  return (
    <div className={'nav-filters'}>
      <SelectFilter label="Format" filterKey={FORMAT} />
      <SelectFilter label="Cost" filterKey={COST} />
      <DatePickerFilter label="Starting Month" filterKey={START} />
      <SelectFilter label="Duration" size="large" filterKey={DURATION} />
      <SelectFilter label="Level" size="large" filterKey={LEVEL} />
      <ClearFilter label={'Clear filter'} />
    </div>
  );
};

export default NavMenu;
