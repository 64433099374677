import React from 'react';
import classNames from 'classnames';
import useCoursesResultsFilter from '@modules/CareerDevelopment/Courses/Results/Viewer/AdditionalFilters/helpers/hooks/useCoursesResultsFilter';
import {
  FORMAT,
  COST,
  START,
  DURATION,
  LEVEL,
  DEFAULT_VALUE,
  DEFAULT_DATE,
} from '@modules/CareerDevelopment/Courses/Results/Viewer/AdditionalFilters/helpers/constants';

const ClearFilter = ({
  label = '',
  isVisible,
  queryParamsOnChange = true,
  onClick,
}) => {
  const [filters, setFilters] = useCoursesResultsFilter();

  const isDefaultFormat = filters?.format === DEFAULT_VALUE || !filters?.format;
  const isDefaultCost = (filters?.cost === DEFAULT_VALUE) | !filters?.cost;
  const isDefaultStart = filters?.start === DEFAULT_DATE || !filters?.start;
  const isDefaultDuration =
    filters?.duration === DEFAULT_VALUE || !filters?.duration;
  const isDefaultLevel = filters?.level === DEFAULT_VALUE || !filters?.level;

  const getButtonVisibility = () => {
    if (typeof isVisible === 'boolean') {
      return !isVisible;
    }

    const isFilterActive =
      !isDefaultFormat ||
      !isDefaultCost ||
      !isDefaultStart ||
      !isDefaultDuration ||
      !isDefaultLevel;

    return !isFilterActive;
  };

  const handleClearFiltersButton = () => {
    if (queryParamsOnChange) {
      setFilters({
        [FORMAT]: undefined,
        [COST]: undefined,
        [START]: undefined,
        [DURATION]: undefined,
        [LEVEL]: undefined,
      });
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className={classNames('clear-filters', {
        _invisible: getButtonVisibility(),
      })}
    >
      <button
        className={'clear-filter-button'}
        onClick={handleClearFiltersButton}
      >
        {label}
      </button>
    </div>
  );
};

export default ClearFilter;
