import formatDate from '@helpers/methods/formatDate';
import removeNullFromContents from '../utils/removeNullFromContents';

import calculateDuration from '@helpers/methods/calculateDuration';
import getLocationName from '@templates/CoursesContentViewer/helpers/methods/getLocationName';
import getLocationIcon from '@templates/CoursesContentViewer/helpers/methods/getLocationIcon';
import getLocationType from '@templates/CoursesContentViewer/helpers/methods/getLocationType';

import getSponsorType from '@templates/CoursesContentViewer/helpers/methods/getSponsorType';
import getSponsorLabel from '@templates/CoursesContentViewer/helpers/methods/getSponsorLabel';
import getSponsorLogoUrl from '@templates/CoursesContentViewer/helpers/methods/getSponsorLogoUrl';
import selectAuthor from '../utils/selectAuthor';

const DEFAULT_SETTINGS = {
  isUpcomingCoursesOnly: false,
};

const sanitizeArray = (array = []) => {
  return array ?? array?.nodes ?? [];
};

const removeOngoingCourses = (courses) => {
  const coursesWithoutOngoing = courses.map((course) => {
    const currentDate = new Date().toISOString();
    const isCourseStartDateEmpty = !course?.start_date;
    const isCourseStartDateOngoing = course?.start_date <= currentDate;
    if (isCourseStartDateOngoing || isCourseStartDateEmpty) {
      return null;
    }

    return course;
  });

  return removeNullFromContents(coursesWithoutOngoing);
};

const generateCourseCardsProps = (courses) => {
  const addCostType = (courseCardProps, course) => {
    return {
      ...courseCardProps,

      costType:
        getSponsorLabel(course?.cost) ?? course?.pricing_model?.value ?? '',
    };
  };

  const addLogoUrl = (courseCardProps, course) => {
    return {
      ...courseCardProps,

      logoUrl: getSponsorLogoUrl(course?.cost) ?? '',
    };
  };

  const addAuthor = (courseCardProps, course) => {
    return {
      ...selectAuthor(courseCardProps, course),
    };
  };

  const addMiddotContent = (courseCardProps, course) => {
    const deadlineDate = formatDate(course?.registration_deadline);
    const startDate = formatDate(course?.start_date);
    const REGISTER_UNTIL = deadlineDate
      ? `Register until ${deadlineDate}`
      : null;
    const STARTS_ON = startDate ? `Starts on ${startDate}` : null;

    return {
      ...courseCardProps,

      middotContent: [REGISTER_UNTIL, STARTS_ON],
    };
  };

  const addLocation = (courseCardProps, course) => {
    return {
      ...courseCardProps,

      location: {
        name: getLocationName(course?.location),
        icon: getLocationIcon(course?.location),
        type: getLocationType(course?.location),
      },
    };
  };

  const addCost = (courseCardProps, course) => {
    return {
      ...courseCardProps,

      cost: {
        type:
          getSponsorType(course?.cost) ?? course?.pricing_model?.value ?? '',
        label: getSponsorLabel(course?.cost),
      },
    };
  };

  const addDuration = (courseCardProps, course) => {
    return {
      ...courseCardProps,

      duration: calculateDuration(course?.duration),
    };
  };

  const addOtherProps = (courseCardProps, course) => {
    return {
      ...courseCardProps,

      id: course?.id ?? '',
      category: course?.courses_category?.name ?? '',
      title: course?.title ?? '',
      link: course?.slug ?? '',
      level: course?.level?.label ?? '',

      deadline: course?.registration_deadline,
      startdate: course?.start_date,
      creator_id: course?.creator_id,
    };
  };

  const courseCardsProps = courses.map((course) => {
    let courseCardProps;

    courseCardProps = addCostType(courseCardProps, course);
    courseCardProps = addLogoUrl(courseCardProps, course);
    courseCardProps = addAuthor(courseCardProps, course);
    courseCardProps = addMiddotContent(courseCardProps, course);
    courseCardProps = addLocation(courseCardProps, course);
    courseCardProps = addCost(courseCardProps, course);
    courseCardProps = addDuration(courseCardProps, course);
    courseCardProps = addOtherProps(courseCardProps, course);

    return courseCardProps;
  });

  return courseCardsProps;
};

const getCourseCardsProps = (courses = [], isUpcomingCoursesOnly) => {
  let courseCardsProps = sanitizeArray(courses);

  if (isUpcomingCoursesOnly) {
    courseCardsProps = removeOngoingCourses(courseCardsProps);
  }

  courseCardsProps = generateCourseCardsProps(courseCardsProps);

  return courseCardsProps;
};

export default getCourseCardsProps;
