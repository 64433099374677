const calculateDuration = (duration) => {
  const isDurationEmpty = !duration;
  if (isDurationEmpty) return null;

  const getDurationText = (durationInDays) => {
    if (durationInDays === 1) return '1 Day';
    if (durationInDays === 2) return '2 Days';
    if (durationInDays === 3) return '3 Days';
    if (durationInDays === 4) return '4 Days';
    if (durationInDays === 5) return '5 Days';
    if (durationInDays === 6) return '6 Days';

    if (durationInDays >= 7 && durationInDays <= 13) return '1 Week';
    if (durationInDays >= 14 && durationInDays <= 20) return '2 Weeks';
    if (durationInDays >= 21 && durationInDays <= 27) return '3 Weeks';

    if (durationInDays >= 28 && durationInDays <= 34) return '1 Month';
    if (durationInDays >= 61 && durationInDays <= 90) return '2 Months';
    if (durationInDays >= 91 && durationInDays <= 121) return '3 Months';
    if (durationInDays >= 122 && durationInDays <= 151) return '4 Months';
    if (durationInDays >= 152 && durationInDays <= 182) return '5 Months';
    if (durationInDays >= 183 && durationInDays <= 212) return '6 Months';
    if (durationInDays >= 213 && durationInDays <= 242) return '7 Months';
    if (durationInDays >= 243 && durationInDays <= 273) return '8 Months';
    if (durationInDays >= 274 && durationInDays <= 303) return '9 Months';
    if (durationInDays >= 304 && durationInDays <= 334) return '10 Months';
    if (durationInDays >= 335 && durationInDays <= 364) return '11 Months';
    if (durationInDays === 365) return '12 Months';
    if (durationInDays > 365) return '12+ Months';

    return null;
  };

  return { label: getDurationText(duration), value: duration };
};

export default calculateDuration;
