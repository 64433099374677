import React from 'react';
import ClearFilter from '@modules/CareerDevelopment/Courses/Results/Viewer/AdditionalFilters/ClearFilter';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';

const Header = ({ onClose, onClear, isClearAllVisible }) => {
  return (
    <div className="header">
      <ClearFilter
        label="Clear All"
        queryParamsOnChange={false}
        onClick={onClear}
        isVisible={isClearAllVisible}
      />
      <p className="header-label">Filters</p>

      <button onClick={onClose}>
        <SpriteIconViewer spriteId={'close'} />
      </button>
    </div>
  );
};

export default Header;
