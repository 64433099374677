interface LocationType {
  province: { name: string };
  address: string;
  city: string;
  type: string;
}

const getLocationType = (locations: LocationType[]): string => {
  const DEFAULT_TYPE = '';
  const isLocationsEmpty = locations?.length === 0;
  const isNotArrayType = !Array.isArray(locations);
  const isLocationsInvalid = isLocationsEmpty || isNotArrayType;
  if (isLocationsInvalid) return DEFAULT_TYPE;

  return locations[0].type;
};

export default getLocationType;
