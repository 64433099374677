import React, { useState, useEffect } from 'react';
import CourseCard from '@components/Cards/CourseCard';
import EmptyStateViewer from '../EmptyStateViewer';
import LoadMoreButton from '../LoadMoreButton';

const END_INDEX = 6;
const START_INDEX = 0;

const DEFAULT_CONTENT_VIEWER_STATE = {
  endIndex: END_INDEX,
  isLoadMoreVisible: false,
  isEmptyStateVisible: false,
};

const CourseCardsViewer = ({
  contents = [],
  linkRoot = '',
  className = '',
  EmptyState = null,
}) => {
  const [contentViewerState, setContentViewerState] = useState(
    DEFAULT_CONTENT_VIEWER_STATE
  );

  const {
    endIndex,
    isLoadMoreVisible,
    isEmptyStateVisible,
  } = contentViewerState;

  const hasNoContent = contents.length === 0;

  useEffect(() => {
    setContentViewerState({
      ...contentViewerState,
      isLoadMoreVisible: contents.length > endIndex,
      isEmptyStateVisible: hasNoContent,
    });
  }, [JSON.stringify(contents)]);

  const handleLoadMoreClick = () => {
    setContentViewerState({
      ...contentViewerState,
      endIndex: endIndex + END_INDEX,
      isLoadMoreVisible: contents.length > endIndex + END_INDEX,
    });
  };

  return (
    <div className={`article-cards-viewer-container ${className}`}>
      <EmptyStateViewer EmptyState={EmptyState} isVisible={isEmptyStateVisible}>
        <div className="article-cards-viewer">
          {contents.slice(START_INDEX, endIndex).map((content) => {
            const slug = content.link;
            const link = `${linkRoot}${slug}`;
            return <CourseCard {...content} key={slug} link={link} />;
          })}
        </div>

        <LoadMoreButton
          isVisible={isLoadMoreVisible}
          onClick={handleLoadMoreClick}
        />
      </EmptyStateViewer>
    </div>
  );
};

export default CourseCardsViewer;
