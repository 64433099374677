import { ALL_CATEGORY, DEFAULT_ID } from '@helpers/constants';
import { useStaticQuery, graphql } from 'gatsby';

import getCourseCardsProps from '@helpers/methods/modifyContents/getCourseCardsProps';
import useFlexSuggestionIndex from '@helpers/hooks/useFlexSuggestionIndex';
import sortByPublishedDate from '@helpers/methods/sortByPublishedDate';

const DEFAULT_CATEGORY = {
  strapiId: DEFAULT_ID,
  name: ALL_CATEGORY,
};

const Fetcher = ({ children }) => {
  const {
    allStrapiCourses,
    allStrapiCoursesCategories,
    flexSearchOptions,
    flexSuggestionOptions,
  } = useStaticQuery(
    graphql`
      query {
        allStrapiCourses(filter: { content_status: { value: { eq: "2" } } }) {
          nodes {
            id
            strapiId
            created_at(formatString: "MMM DD, YYYY")
            published_date
            published_at
            content
            courses_category {
              name
            }
            slug
            title
            duration
            registration_deadline
            deadline_preview: registration_deadline(formatString: "MMM DD")
            start_date
            end_date
            start_date_preview: start_date(formatString: "MMM DD, YYYY")
            level {
              label
              value
            }
            cost {
              label
              type
              sponsor_name
              sponsorLogo: sponsor_logo {
                localFile {
                  extension
                  publicURL
                }
              }
            }

            creator_id
            author {
              username
            }
            created_by {
              username
            }

            location {
              province {
                name
              }
              type
            }
          }
        }

        allStrapiCoursesCategories(sort: { fields: sort, order: ASC }) {
          nodes {
            name
          }
        }

        flexSearchOptions: localSearchCourses {
          index
          store
        }

        flexSuggestionOptions: localSearchCoursesSuggestions {
          index
        }
      }
    `
  );

  let courseCardItems = sortByPublishedDate(allStrapiCourses?.nodes);
  courseCardItems = getCourseCardsProps(courseCardItems);

  const coursesCategories = [
    DEFAULT_CATEGORY,
    ...allStrapiCoursesCategories.nodes,
  ];

  const suggestedWordsIndex = useFlexSuggestionIndex(
    flexSuggestionOptions?.index
  );
  const suggestedTitlesIndex = courseCardItems.map(({ title }) => title);
  const searchSuggestions = [...suggestedWordsIndex, ...suggestedTitlesIndex];

  return children({
    courseCardItems,
    coursesCategories,
    flexSearchOptions,
    searchSuggestions,
  });
};

export default Fetcher;
