import React, { useState, useEffect } from 'react';
import Modal from 'antd/lib/modal';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';
import SelectFilter from '@modules/CareerDevelopment/Courses/Results/Viewer/AdditionalFilters/SelectFilter';
import DatePickerFilter from '@modules/CareerDevelopment/Courses/Results/Viewer/AdditionalFilters/DatePickerFilter';
import Header from '@modules/CareerDevelopment/Courses/Results/Viewer/AdditionalFilters/HamMenu/Header';
import Button from 'antd/lib/button';
import useCoursesResultsFilter from '@modules/CareerDevelopment/Courses/Results/Viewer/AdditionalFilters/helpers/hooks/useCoursesResultsFilter';

import {
  FORMAT,
  COST,
  START,
  DURATION,
  LEVEL,
  DEFAULT_VALUE,
  DEFAULT_DATE,
  DEFAULT_FILTERS,
} from '@modules/CareerDevelopment/Courses/Results/Viewer/AdditionalFilters/helpers/constants';

const HamMenu = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [queryParamsFilters, setQueryParamsFilters] = useCoursesResultsFilter();

  const ADVANCED_FILTERS = {
    [FORMAT]: queryParamsFilters[FORMAT],
    [COST]: queryParamsFilters[COST],
    [START]: queryParamsFilters[START],
    [DURATION]: queryParamsFilters[DURATION],
    [LEVEL]: queryParamsFilters[LEVEL],
  };

  const [filters, setFilters] = useState(ADVANCED_FILTERS);

  useEffect(() => {
    setFilters(ADVANCED_FILTERS);
  }, [
    queryParamsFilters[FORMAT],
    queryParamsFilters[COST],
    queryParamsFilters[START],
    queryParamsFilters[DURATION],
    queryParamsFilters[LEVEL],
  ]);

  const getClearAllVisibility = () => {
    const isDefaultFormat =
      filters?.format === DEFAULT_VALUE || !filters?.format;
    const isDefaultCost = (filters?.cost === DEFAULT_VALUE) | !filters?.cost;
    const isDefaultStart = filters?.start === DEFAULT_DATE || !filters?.start;
    const isDefaultDuration =
      filters?.duration === DEFAULT_VALUE || !filters?.duration;
    const isDefaultLevel = filters?.level === DEFAULT_VALUE || !filters?.level;

    return (
      !isDefaultFormat ||
      !isDefaultCost ||
      !isDefaultStart ||
      !isDefaultDuration ||
      !isDefaultLevel
    );
  };

  const openModal = () => {
    setIsModalVisible(true);
    document.body.classList.add('modal-open');
  };

  const closeModal = () => {
    setIsModalVisible(false);
    document.body.classList.remove('modal-open');
  };

  const handleButtonClick = () => {
    openModal();
  };

  const handleButtonCancel = () => {
    closeModal();
  };

  const handleFormatSelectChange = (value) => {
    setFilters({
      ...filters,
      [FORMAT]: value,
    });
  };

  const handleCostSelectChange = (value) => {
    setFilters({
      ...filters,
      [COST]: value,
    });
  };

  const handleStartDatePickerChange = (value) => {
    setFilters({
      ...filters,
      [START]: value,
    });
  };

  const handleDurationSelectChange = (value) => {
    setFilters({
      ...filters,
      [DURATION]: value,
    });
  };

  const handleLevelSelectChange = (value) => {
    setFilters({
      ...filters,
      [LEVEL]: value,
    });
  };

  const handleApplyButton = () => {
    setQueryParamsFilters(filters);
    closeModal();
  };

  const handleCloseButton = () => {
    setFilters(ADVANCED_FILTERS);
    closeModal();
  };

  const handleClearButton = () => {
    setFilters(DEFAULT_FILTERS);
  };

  return (
    <div className="ham-filters">
      <button className={'ham-menu-button'} onClick={handleButtonClick}>
        <SpriteIconViewer className={'button-icon'} spriteId="filter" />
        <p className={'button-label'}>Filter</p>
      </button>

      <Modal
        className={'ham-menu'}
        width={'100vw'}
        footer={null}
        maskClosable={false}
        visible={isModalVisible}
        onCancel={handleButtonCancel}
        centered={true}
        closable={false}
        destroyOnClose={true}
        wrapClassName={'ham-filters-modal'}
      >
        <section className="filters-header-section">
          <Header
            onClose={handleCloseButton}
            onClear={handleClearButton}
            isClearAllVisible={getClearAllVisibility()}
          />
        </section>

        <section className="filters-section">
          <SelectFilter
            value={filters[FORMAT]}
            label="Format"
            filterKey={FORMAT}
            queryParamsOnChange={false}
            onChange={handleFormatSelectChange}
          />
          <SelectFilter
            value={filters[COST]}
            label="Cost"
            filterKey={COST}
            queryParamsOnChange={false}
            onChange={handleCostSelectChange}
          />
          <DatePickerFilter
            value={filters[START]}
            label="Starting Month"
            filterKey={START}
            queryParamsOnChange={false}
            onChange={handleStartDatePickerChange}
          />
          <SelectFilter
            value={filters[DURATION]}
            label="Duration"
            filterKey={DURATION}
            queryParamsOnChange={false}
            onChange={handleDurationSelectChange}
          />
          <SelectFilter
            value={filters[LEVEL]}
            label="Level"
            filterKey={LEVEL}
            queryParamsOnChange={false}
            onChange={handleLevelSelectChange}
          />
        </section>

        <section className="filters-submit-section">
          <Button type="primary" onClick={handleApplyButton}>
            Apply
          </Button>
        </section>
      </Modal>
    </div>
  );
};

export default HamMenu;
