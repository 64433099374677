import React from 'react';
import HamMenu from './HamMenu';
import NavMenu from './NavMenu';

const AdditionalFilters = () => {
  return (
    <div className="additional-filters">
      <HamMenu />
      <NavMenu />
    </div>
  );
};

export default AdditionalFilters;
