import {
  SORT_RECENT,
  SORT_UPCOMING,
  SORT_POPULAR,
  ALL_CATEGORY,
  DEFAULT_SORT,
  DEFAULT_FILTERS,
  DEFAULT_CONTENT,
  DEFAULT_CATEGORY,
} from '@helpers/constants';

import moment from 'moment';

const searchContent = (
  contents = DEFAULT_CONTENT,
  searchResults = DEFAULT_CONTENT,
  queryString = ''
) => {
  const hasEmptySearchBar = queryString === '';
  if (hasEmptySearchBar) return contents;

  const searchedContent = contents.filter((content) => {
    const doesItemExist = searchResults.find((result, idx) => {
      if (result !== content.id) return false;
      return true;
    });
    return doesItemExist ? true : false;
  });
  return searchedContent;
};

const sortContent = (contents = DEFAULT_CONTENT, sort = DEFAULT_SORT) => {
  switch (sort) {
    case SORT_RECENT:
      return contents;
    case SORT_UPCOMING:
      return contents.sort((itemA, itemB) => {
        if (itemA.deadline < itemB.deadline) return -1;
        if (itemA.deadline > itemB.deadline) return 1;
        return 0;
      });
    case SORT_POPULAR:
      return contents;
    default:
      return DEFAULT_CONTENT;
  }
};

const categorizeContent = (
  contents = DEFAULT_CONTENT,
  category = DEFAULT_CATEGORY
) => {
  const isAllSelected = category === ALL_CATEGORY;
  if (isAllSelected) return contents;

  const categorizedContent = contents.filter(
    (content) => content?.category === category
  );

  return categorizedContent;
};

const filterContentByFormat = (contents = DEFAULT_CONTENT, format = '') => {
  if (format === 'All' || !format) return contents;
  return contents.filter((content) => content?.location?.type === format);
};

const filterContentByCost = (contents = DEFAULT_CONTENT, cost = '') => {
  const validCostTypes = ['free', 'paid', 'sponsored'];
  const isInvalidCostType = !validCostTypes.includes(cost);
  if (isInvalidCostType) return contents;
  return contents.filter(
    (content) => content?.cost?.type?.toLowerCase?.() === cost?.toLowerCase?.()
  );
};

const filterContentByStartingMonth = (
  contents = DEFAULT_CONTENT,
  monthYear = ''
) => {
  const isMonthYearInvalid = !moment(monthYear, 'MMMM YYYY').isValid();
  if (isMonthYearInvalid) return contents;

  return contents.filter((content) => {
    const contentMonthYear = moment(content.startdate, 'YYYY-MM-DD').format(
      'MMMM YYYY'
    );
    return contentMonthYear === monthYear;
  });
};

const filterContentByDuration = (contents = DEFAULT_CONTENT, duration = '') => {
  const validDuration = ['<1d', '1-4w', '1-3m', '4-6m', '7-12m', '>12m'];
  const isInvalidDuration = !validDuration.includes(duration);
  if (isInvalidDuration) return contents;

  switch (duration) {
    case '<1d':
      return contents.filter((content) => content?.duration?.value < 1);
    case '1-4w':
      return contents.filter(
        (content) =>
          content?.duration?.value >= 1 && content?.duration?.value <= 30.4167
      );
    case '1-3m':
      return contents.filter(
        (content) =>
          content?.duration?.value > 30.4167 &&
          content?.duration?.value <= 121.667
      );
    case '4-6m':
      return contents.filter(
        (content) =>
          content?.duration?.value > 121.667 &&
          content?.duration?.value <= 212.917
      );
    case '7-12m':
      return contents.filter(
        (content) =>
          content?.duration?.value > 212.917 && content?.duration?.value <= 365
      );
    case '>12m':
      return contents.filter((content) => content?.duration?.value > 365);
    default:
      return contents;
  }
};

const filterContentByLevel = (contents = DEFAULT_CONTENT, level = '') => {
  const validLevel = ['beg', 'int', 'adv', 'com'];
  const isInvalidLevel = !validLevel.includes(level);
  if (isInvalidLevel) return contents;

  switch (level) {
    case 'beg':
      return contents.filter((content) => {
        return content?.level === 'Beginner';
      });
    case 'int':
      return contents.filter((content) => content?.level === 'Intermediate');
    case 'adv':
      return contents.filter((content) => content?.level === 'Advanced');
    case 'com':
      return contents.filter((content) => content?.level === 'Comprehensive');
    default:
      return contents;
  }
};

const filterContentByAuthor = (contents = DEFAULT_CONTENT, author = '') => {
  if (!author) return contents;

  return contents.filter((content) => {
    const hasUserNameMatches = content?.author?.username === author;
    const hasCreatorIdMatches = content?.creator_id === author;
    return hasUserNameMatches || hasCreatorIdMatches;
  });
};

const filterCourseItems = (
  contents = DEFAULT_CONTENT,
  filters = DEFAULT_FILTERS
) => {
  const {
    search,
    query,
    sort,
    category,
    format,
    cost,
    start,
    duration,
    level,
    author,
  } = filters;

  let filteredContent = contents;
  filteredContent = categorizeContent(filteredContent, category);
  filteredContent = sortContent(filteredContent, sort);
  filteredContent = searchContent(filteredContent, search, query);
  filteredContent = filterContentByFormat(filteredContent, format);
  filteredContent = filterContentByCost(filteredContent, cost);
  filteredContent = filterContentByStartingMonth(filteredContent, start);
  filteredContent = filterContentByDuration(filteredContent, duration);
  filteredContent = filterContentByLevel(filteredContent, level);
  filteredContent = filterContentByAuthor(filteredContent, author);
  return filteredContent;
};

export default filterCourseItems;
