import React from 'react';
import {
  useQueryParams,
  StringParam,
  ArrayParam,
  withDefault,
} from 'use-query-params';
import NoResultEmptyState from '@components/NoResultEmptyState';
import SEO from '@components/SEO';
import Layout from '@components/Layout/LayoutTemplates/CareerDevelopmentLayout';
import ResultsPageSearch from '@components/SearchWithCTA/ResultsPageSearch';
import Topbar from '@components/Topbar';
import CourseCardsViewer from '@components/ArticleCardsViewer/CourseCardsViewer';
import AdditionalFilters from './AdditionalFilters';
import CategorySlide from '@components/CategorySlide';
import Section from '@components/Section';
import filterCourseItems from '@helpers/methods/filterCourseItems';
import { ALL_CATEGORY, SORT_ERROR_LOG } from '@helpers/constants';
import {
  SORT_RECENT,
  SORT_UPCOMING,
  SORT_POPULAR,
  SORT_OPTIONS,
} from '@modules/CareerDevelopment/Courses/helpers/constants';

const coursesOptions = {
  sort: [
    { value: SORT_RECENT, label: SORT_RECENT },
    { value: SORT_UPCOMING, label: SORT_UPCOMING },
    { value: SORT_POPULAR, label: SORT_POPULAR },
  ],
};

const ResultsPreview = ({ data }) => {
  const {
    courseCardItems,
    coursesCategories,
    flexSearchOptions,
    searchSuggestions,
  } = data;

  const [filters, setFilters] = useQueryParams({
    category: withDefault(StringParam, ALL_CATEGORY),
    sort: withDefault(StringParam, SORT_RECENT),
    query: withDefault(StringParam, ''),
    search: withDefault(ArrayParam, []),
    format: withDefault(StringParam, ''),
    cost: withDefault(StringParam, ''),
    start: withDefault(StringParam, ''),
    duration: withDefault(StringParam, ''),
    level: withDefault(StringParam, ''),
    author: withDefault(StringParam, ''),
  });

  const handleCategoryClick = (category) => () => {
    setFilters({ category });
  };

  const handleSortByChange = (sort) => {
    const isValueExpected = SORT_OPTIONS.includes(sort);
    if (!isValueExpected) {
      console.error(SORT_ERROR_LOG);
      return;
    }
    setFilters({
      sort,
    });
  };

  const handleSearchQuery = (response) => {
    const { results, query } = response;

    const isQueryInvalid = typeof query !== 'string';
    if (isQueryInvalid) return;

    const hasSameQueryAsBefore = filters.query === query;
    if (hasSameQueryAsBefore) return;

    const sanitizeQuery = (queryString) => {
      const isQueryEmptyString = queryString === '';
      return isQueryEmptyString ? undefined : queryString;
    };

    setFilters({
      search: results,
      query: sanitizeQuery(query),
    });
  };
  const visibleCourseItems = filterCourseItems(courseCardItems, filters);

  return (
    <Layout className={'courses-results-container'}>
      <SEO title="Courses Results" description="Results" />
      <ResultsPageSearch
        header="Ready for Your Next Lesson?"
        searchPlaceholder="Search from our courses"
        defaultValue={filters.query}
        onQuery={handleSearchQuery}
        flexSearchOptions={flexSearchOptions}
        searchSuggestions={searchSuggestions}
      />
      <Section>
        <CategorySlide
          className="result-preview-category"
          categories={coursesCategories}
          currentCategory={filters.category}
          onClick={handleCategoryClick}
        />
      </Section>

      <div className="career-advice-results-preview-container">
        <Section className={'additional-filters-section'}>
          <AdditionalFilters />
        </Section>

        <Section>
          <Topbar
            options={coursesOptions}
            defaultValue={filters.sort}
            onChange={handleSortByChange}
            count={visibleCourseItems?.length}
          />
        </Section>

        <Section className="courses-results-cards-viewer-section">
          <CourseCardsViewer
            EmptyState={<NoResultEmptyState />}
            contents={visibleCourseItems}
            linkRoot={'/career-development/courses/'}
          />
        </Section>
      </div>
    </Layout>
  );
};

export default ResultsPreview;
